.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(186, 141, 100, 1);
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 80px;
  width: auto;
}

.title {
  font-family: 'gg sans', sans-sherif;
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
}

.header-red-letter {
  font-family: 'gg sans', sans-sherif;
  color: rgba(255, 76, 62, 1);
  font-size: 1.8rem;
  font-weight: bold;
  margin-left: 5px;
}

.header-blue-letter {
  font-family: 'gg sans', sans-sherif;
  color: rgba(25, 206, 234, 1);
  font-size: 1.8rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .company-name {
    font-size: 1.8rem;
  }

  .logo {
    height:80px;
  }
}
