.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 80px;
  background-color: rgb(186, 141, 100, 1);
}

.App-header {
  font-size: 2rem;
  color: #282c34;
}
