.edit-container {
  background-color: rgba(25, 206, 234, 1);
  font-family: 'gg sans', sans-sherif;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 50%;
}

.edit-container h2 {
  color: black;
  margin-bottom: 20px;
}

.edit-container label {
  display: block;
  color: black;
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="date"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-family: 'gg sans', sans-sherif;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
}

.edit-container input[type="text"],
.edit-container input[type="email"] {
  width: calc(90% - 20px);
}

.edit-container .activation-key {
  color: black;
  margin-bottom: 10px;
  text-align: center;
}

.edit-container .chackbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.edit-container .checkbox-container label {
  margin: 5px;
  font-weight: bold;
}

.edit-container .checkbox-container input[type="checkbox"] {
  margin-left: 10px;
}

.edit-container button {
  background-color: #35d9a6;
  color: black;
  padding: 10px 20px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'gg sans', sans-sherif;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
}

.edit-container button:hover {
  background-color: #ff4c3e;
}

.edit-container .edit-form-group {
  display: flex;
  justify-content: space-between;
}

.edit-container .edit-form-group label {
  width: 90%;
}

.edit-container .edit-form-group input[type="text"]#id {
  width: 2%;
}

.edit-container .edit-form-group input[type="text"]#company-name {
  width: 85%;
}

.edit-container .edit-form-group input[type="text"]#first-name
.edit-container .edit-form-group input[type="text"]#last-name {
  width: 50%;
}

.edit-container .edit-form-group input[type="email"]#email {
  width: calc(90% - 120px);
}

.edit-container .edit-form-group input[type="date"]#last-payment {
  width: 120px;
}

.edit-container .edit-form-group .activation-key {
  width: calc(100% - 120px);
}

.edit-container .edit-form-group .checkbox-container {
  width: 100px;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .edit-container {
    width: 90%;
    padding: 10px;
  }
  .edit-container .edit-form-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .edit-container .edit-form-group label,
  .edit-container .edit-form-group input[type="text"],
  .edit-container .edit-form-group input[type="email"],
  .edit-container .edit-form-group input[type="date"],
  .edit-container .edit-form-group .activation-key,
  .edit-container .edit-form-group .checkbox-container {
    width: 87%;
  }
  .edit-container .edit-form-group input[type="text"]#id,
  .edit-container .edit-form-group input[type="text"]#company-name,
  .edit-container .edit-form-group input[type="text"]#first-name,
  .edit-container .edit-form-group input[type="text"]#last-name,
  .edit-container .edit-form-group input[type="email"]#email,
  .edit-container .edit-form-group input[type="date"]#last-payment,
  .edit-container .edit-form-group .activation-key,
  .edit-container .edit-form-group .checkbox-container {
    width: 90%;
    margin-bottom: 10px;
    padding: 6px;
  }
  .edit-container .edit-form-group .checkbox-container {
    flex-direction: row;
    justify-content: space-between;
  }
}
