.dashboard {
  width: 90%;
  background-color: rgb(186, 141, 100, 1);
  font-family: 'gg sans', sans-sherif;
  margin: 20px auto;
  text-align: center;
}

.dashboard-header {
  margin-bottom: 20px;
}

.create-clients-form {
  display: flex;
  align-items: center;
}

.create-clients-form input[type="number"] {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 50px;
  font-size: 12px;
}

.create-clients-form button {
  padding: 8px 16px;
  background-color: #35d9a6;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.create-clients-form button:hover {
  background-color: #ff4c3e;
}

.dashboard-error {
  background-color: #ffdddd;
  color: #a94442;
  padding: 10px;
  border: 1px solid #f44336;
  margin-bottom: 20px;
  border-left: 6px solid #f44336;
  font-weight: bold;
  border-radius: 10px;
}

.dashboard-client-table {
  overflow-x: auto;
}

table {
  width: 80%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin: 0 auto;
}

table, th, td {
  border: 1px solid #004d40;
}

th, td {
  padding: 8px;
}

th {
  background-color: rgb(25, 206, 234, 1);
  color: black;
  font-weight: bold;
}

tr:nth-child(odd) {
  background-color: rgb(25, 206, 234, 1);
  color: black;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.dashboard-client-table button {
  background-color: #35d9a6;
  color: black;
  font-weight: bold;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.dashboard-client-table button:hover {
  background-color: #ff4c3e;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-content {
  background-color: rgba(25, 206, 234, 1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.popup-button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.confirm-button {
  background-color: #35d9a6;
  color: black;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cancel-button {
  background-color: rgba(255, 76, 62, 1);
  color: black;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  th, td {
    padding: 5px;
    font-size: 12px;
  }
  .create-client-form input
  .create-client-fprm button {
    padding: 5px 10px;
  }
}

.small-column {
  width: 80px;
}
