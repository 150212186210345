.change-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgba(186, 141, 100, 1);
  padding: 20px;
}

.change-password-form {
  background-color: rgba(25, 206, 234, 1);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5); 
}

.change-password-form h2 {
  font-family: 'gg sans', sans-sherif;
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

.change-password-form-group {
  margin-bottom: 20px;
}

.change-password-form-group label {
  display: block;
  color: black;
  font-family: 'gg sans', sans-sherif;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.change-password-input-field {
  width: 90%;
  padding: 8px;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5); 
}

.change-password-submit-button {
  width: 50%;
  padding: 10px;
  background-color: rgba(18, 167, 121, 1);
  color: black;
  font-family: 'gg sans', sans-sherif;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5); 
}

.change-password-submit-button:hover {
  background-color: rgba(255, 76, 62, 1);
}

.change-password-error {
  color: rgba(255, 76, 62, 1);
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .change-password-form {
    padding: 20px;
  }

  .change-password-form h2 {
    font-size: 1.5rem;
  }

  .input-field {
    font-size: 1rem;
  }

  .submit-button {
    font-size: 1rem;
  }
}
