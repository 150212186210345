.login-container {
  background-color: rgba(25, 206, 234, 1);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 350px;
}

.login-container h2 {
  color: black;
  margin: 0 0 20px;
  font-size: 24px;
}

.login-form {
  font-family: "gg-sans";
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-container input[type="text"],
.login-container input[type="password"] {
  width: 80%;
  font-family: "gg-sans";
  padding: 10px;
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.login-container button {
  background-color: #35d9a6;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: "gg-sans";
  font-size: 16px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.login-container button:hover {
  background-color: rgba(255, 76, 62, 1);
}

.login-error {
  color: rgba(255, 76, 62, 1);
  margin-bottom: 20px;
  text-align: center;
  font-family: "gg-sans";
}

@media (max-width: 600px) {
  .login-container {
    width: 300px;
  }
}
